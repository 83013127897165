import { render, staticRenderFns } from "./bankCodeList.vue?vue&type=template&id=3af1dbbb&scoped=true&"
import script from "./bankCodeList.vue?vue&type=script&lang=js&"
export * from "./bankCodeList.vue?vue&type=script&lang=js&"
import style0 from "./bankCodeList.vue?vue&type=style&index=0&id=3af1dbbb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3af1dbbb",
  null
  
)

export default component.exports