<template>
    <div>
      <VTable :tabltData="tableData" :pagination="pagination" ref="multipleTable" @pageChange="pageChange" @selectData="selectData"  v-loading="loading"  element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading">
          <template slot="tableSearch">
              <el-form :model="searchForm" ref="searchForm" label-width="100px" class="demo-ruleForm" :inline="true">
                  <el-form-item prop="bank_id">
                      <el-select v-model="searchForm.bank_id" filterable clearable  placeholder="请选择银行" class="_el-select" size="small">
                          <el-option v-for="item in ownerBankList" :key="item.bank_id" :label="item.bank_name"
                              :value="item.bank_id">
                          </el-option>
                      </el-select>
                  </el-form-item>
                  <el-form-item prop="card_type">
                      <el-select v-model="searchForm.card_type" filterable clearable  placeholder="请选卡类型" class="_el-select" size="small">
                          <el-option label="借记卡" :value="1"></el-option>
                          <el-option label="信用卡" :value="2"></el-option>
                      </el-select>
                  </el-form-item>            
                  <el-form-item label="" prop="search_keyword">
                      <el-input type="text" size='small' clearable v-model="searchForm.search_keyword" placeholder="请输入银行码编码、码名称"></el-input>
                  </el-form-item>
                  <!-- <el-form-item label="" prop="template_name">
                      <el-input type="text" size='small' clearable v-model="searchForm.qrcode_name" placeholder="请输入码名称"></el-input>
                  </el-form-item>   -->
                  <el-form-item>
                      <el-button type="primary" size='small' @click="submiteSearch('searchForm')">查询</el-button>
                  </el-form-item>
              </el-form>
          </template>
          <template slot="tableTips">
            <div class="tableTips">
               <span @click="batchDownBankCode">批量下载银行码</span><span class="tips2" @click="batchDownqrCode">批量下载单码</span>
            </div>
          </template>
          <template slot='column'>
              <el-table-column
                type="selection"
                width="55">
              </el-table-column>
              <el-table-column type="expand">
                <template slot-scope="props">
                  <div class="tableExpend">
                    <div class="left">
                      <div class="bankInfo">银行信息: {{ getBanName(props.row.bank_id) }}</div>
                      <div class="staffInfo">
                        <div class="staffId" v-show="props.row.channel_type == 2">员工编号: {{props.row.staff_code}}</div>
                        <div class="staffname" v-show="props.row.channel_type == 2">员工姓名: {{props.row.staff_name}}</div>
                        <div class="storeNamr" v-show="props.row.channel_type == 3">商户名称: {{props.row.merchant_name}}</div>
                      </div>
                    </div>
                    <div class="right">
                      <div>绑卡数量: {{props.row.bind_total}}</div>
                      <div class="ff" @click="reportDetail(props.row.qrcode_id)">报表详情</div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="qrcode_name" label="码名称"></el-table-column>
              <el-table-column prop="bank_id" label="银行">
                <template slot-scope="scope">
                    <div>{{scope.row.root_bank_name}}</div>
                </template>
              </el-table-column>
              <el-table-column prop="card_type" label="卡类型">
                <template slot-scope="scope">
                      <div>{{scope.row.card_type == 1 ? '借记卡': '信用卡'}}</div>
                  </template>
              </el-table-column>
              <el-table-column prop="channel_type" label="渠道类型">
                <template slot-scope="scope">
                    <div>{{scope.row.channel_type == 1 ? '银行': (scope.row.channel_type == 2? '员工':'商户')}}</div>
                </template>
              </el-table-column>
              <el-table-column prop="scene" label="银行码编号"></el-table-column>
              <el-table-column prop="updated_time" label="创建时间">
                <template slot-scope="scope">
                    {{ handleTime(scope.row.created_time) }}
                </template>
              </el-table-column>
              <el-table-column prop="name" label="操作">
                  <template slot-scope="scope">
                    <el-button type="text" size="small" @click="jumpRoBankCodeDetail(scope.row)">银行码详情</el-button>
                  </template>
              </el-table-column>
          </template>
      </VTable>
    </div>
</template>

<script>
  import { Message } from 'element-ui';
  import { timestampToTime } from '../../utils/timeset'
  import VTable from '../../components/form/VTable.vue'
  import * as BankCodeList from '../../api/bankList'
  import * as Bank from '../../api/bank'
  import * as Public from '@/api/public'
  
import App from '../../App.vue'
  export default {
    data() {
      return {
        loading: false,
        user_id: '',
        searchForm: {
          bank_id: '',
          card_type: '',
          search_keyword: '',
          // scene: '',
          // qrcode_name: ''
        },
        current_page: 1,
        tableData: [],
        pagination:{},
        bankList: [],
        ownerBankList: [],
        qrcode_ids: []
      }
    },
    components: {
        VTable,
        App
    },
    created() {
      this.getList()
      this.getBankList()
      this.getownerBankList()
    },
    methods: {
        //获取列表
        getList(params) {
          this.loading = true
          let obj = {}
          if(params) {
            obj = Object.assign(params,{current_page: this.current_page,page_size: 10},this.searchForm)
          } else {
            obj = {
              ...this.searchForm,
              current_page: this.current_page,
              page_size: 10
            }
          }
          BankCodeList.BankqcList(obj).then( res => {
            if( res.list && res.list ) {
              this.tableData = res.list
              this.pagination = res.pagination
              this.loading = false
            }
          })
        },
        //选择银行
        getBankList(){
            Bank.Bank({current_page: 1,page_size: 999}).then(res => {
                if(res.list) {
                    this.bankList = res.list
                }
            })
        },
        getownerBankList() {
          Public.ownerBank({current_page: 1,page_size: 999}).then(res => {
                if(res.list) {
                    this.ownerBankList = res.list
                }
            })
        },
        getBanName(id) {
          // debugger
          let name = ''
          if(this.bankList.length) {
            this.bankList.map(item => {
              if(item.bank_id == id) {
                name = item.bank_name
              }
            })
          }
          return name
        },
        //跳转银行码详情
        jumpRoBankCodeDetail(row) {
          if(row.qrcode_id) {
            this.$router.push({path:'/BankCodeDetail', query: {id: row.qrcode_id}})
          } 
        },
        //删除
        reportDetail(id) {
          if(id) {
            this.$router.push({path:'/ReportDetial', query: {id}})
          } else {
            this.$router.push({path:'/ReportDetial'})
          }
        },
        //提交搜索表单
        submiteSearch(formName) {
          this.$refs[formName].validate((valid) => {
            if ( valid ) {
              this.getList()
            }
          })
        },
        //删除
        deleteUser(row) {
          this.loading = true
          let template_id = row.template_id
          Template.deleteTemplate(template_id).then(res => {
            this.loading = false
            this.getList()
          })
        },
        pageChange(value) {
            if(value) {
              this.current_page = value
            }
            this.getList()
        },
        //时间处理
        handleTime(time) {
          return timestampToTime(time)
        },

        // 选择表格数据
        selectData(val) {
          this.qrcode_ids = JSON.parse(JSON.stringify(val)).map( item =>{
            return item.qrcode_id;
          })
        },

        // 批量下载银行码
        batchDownBankCode(){
          this.loading = true
          if (this.qrcode_ids.length > 0) {
            BankCodeList.downBankCode({qrcode_ids: this.qrcode_ids}).then(res=>{
              if(res.file_hash) {
                this.loading = false
                Public.downloadData({file_hash: res.file_hash})
              }
            })
          } else {
            Message.warning('请先选择！')
          }
        },

        // 批量下载单码
        batchDownqrCode(){
         this.loading = true
          if (this.qrcode_ids.length > 0) {
            BankCodeList.downBankCode({qrcode_ids: this.qrcode_ids, signal: true}).then(res=>{
              if(res.file_hash) {
                this.loading = false
                Public.downloadData({file_hash: res.file_hash})
              }
            })
          } else {
            Message.warning('请先选择！')
          }
          
        }
    },
  }
</script>

<style lang="scss" scoped>
  .dialogFooter{
    text-align: right;
  }
  .bindStatus{
    display: flex;
    align-items: center;
    .bindWxImg{
      // border-radius: 50%;
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
    .bankLogo{
      width: 100px;
    }
  }
  .dufauleColor{
    display: inline-block;
    width: 35px;
    height: 35px;
  }
  .tableTips{
    color: #00c520;
    cursor: pointer;
    span{
      // margin-right: 10px;
      padding: 0 5px;
    }
    .tips2{
      border-left: 1px solid #000;
    }
  }
  .tableExpend{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding-left: 10px;
    margin-left: 60px;
    margin-right: 1vw;
    font-size: 10px;
    background: #f4fcff;
    .staffInfo{
      width: 15vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
    }
      .right{
        // width: 15vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .ff{
          color: #00c520;
        }
        .ff:hover{
          cursor: pointer;
        }
      }
      .right>div{
        width: 9vw;
        text-align: center;
      }
  }

</style>